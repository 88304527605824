import React from "react"

const Footer = () => {
  return (
    <footer className="my-12 text-center">
      © {new Date().getFullYear()} {` `}
      <a
        href="https://www.shoppingtracker.app/"
        target="_blank"
        rel="noreferrer"
        title="Shopping Tracker "
      >
        Shopping Tracker
      </a>
      {` `}&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
      <a
        href="https://www.shoppingtracker.app/privacyPolicy"
        target="_blank"
        rel="noreferrer"
        title="Privacy Policy "
      >
        Privacy Policy
      </a>
      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
      <a
        href="https://www.shoppingtracker.app/imprint"
        target="_blank"
        rel="noreferrer"
        title="Imprint/Impressum"
      >
        Imprint/Impressum
      </a>
      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
      <a
        href="https://www.shoppingtracker.app/tos"
        target="_blank"
        rel="noreferrer"
        title="Terms and conditions"
      >
        Terms and conditions
      </a>
      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
      <p>For more information contact: info@shoppingtracker.app</p>
      &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
    </footer>
  )
}

export default Footer
